import React, { useEffect, useState, useRef } from 'react'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import socket from '../Components/socket'
import '../css/landing.css'
import Rightcontainer from '../Components/Rightcontainer'
import Downloadbutton from '../Components/Downloadbutton'
import io from '../Components/socket'
import Header from '../Components/Header'
import { Token } from '@mui/icons-material'

export default function Landing ({ userAllData }) {
  const [open, setOpen] = useState(false)
  // const [userAllData, setUserAllData] = useState()

  const addsetting = localStorage.getItem('sitSetting')
  const addCaseSetting = JSON.parse(addsetting)
  // console.log(addCaseSetting);

  // const role = async () => {
  //   const access_token = localStorage.getItem('token')
  //   io.emit('getprofile', access_token)
  //   io.on('setProfile', data => {
  //     // setUserAllData(data)
  //   })
  // }

  useEffect(() => {
    // role()
  }, [])


  

  return (
    <>
      <div className='leftContainer mb_space comman-color'>
        <div className='pt-3 mt-5   Orher_page_main_section'>
          <div className='main-area'>
            <div className='gameCard'>
              <div className='alrt_msg_div'>
                <h5 className=''>
                  {JSON.parse(localStorage.getItem('sitSetting')) == 'undefined'
                    ? ''
                    : JSON.parse(localStorage.getItem('sitSetting'))
                        ?.site_message}
                </h5>
              </div>
            </div>

             {JSON.parse(localStorage.getItem('udata'))?.verified === "unverified" ?  <div className='collapseCard-container'>
            <div className='collapseCard'>
              <Link to='/Profile'  style={{ textDecoration: 'none' }}>
                <div
                  className='collapseCard-body'
                  style={{
                    border:'1px solid red',
                    height: '50px',
                    opacity: 1,
                    transition: 'height 0.3s ease 0s, opacity 0.3s ease 0s'
                  }}
                >
                  <div className='collapseCard-text text-dark'>
                    <span className=' text-danger'>
                     Please Complete Your Kyc
                    </span>
                  </div>
                </div>
              </Link>

             
            </div>
          </div> :""}
         

            <section className='games-section'>
              <div className='d-flex align-items-center justify-content-between mt-3 mb-0'>
                
                <h3 className='tour_heading'>Our Tournaments</h3>
               
              </div>
              <div className='games-section-headline mt-0 mb-1'>
                <div className='games-window '>
                 

                <>
                    {/* =================Ludo Classic================ */}

                <Link
                  className='gameCard-container'
                  to={`/Homepage/ludoClassicManual`}
                >
                  <span className='d-none blink text-danger d-block text-right game_status_live'>
                    ◉ LIVE
                  </span>
                  <picture className='gameCard-image'>
                    <img
                     
                      src={
                        process.env.PUBLIC_URL +
                        './mainimage/ludo-classic.jpg'
                      }
                      alt=''
                    />
                  </picture>
                </Link>

                   {/* =================Ludo Lite Classic================ */}
                
                   <Link
                  className='gameCard-container'
                  // to={`/Homepage/ludoClassicRich`}
                  to={`#`}
                >
                  <span className='d-none blink text-danger d-block text-right game_status_live'>
                    ◉ COMING SOON
                  </span>
                  <picture className='gameCard-image'>
                    <img
                     
                      src={
                        process.env.PUBLIC_URL +
                        './mainimage/snake.jpg'
                      }
                      alt=''
                    />
                  </picture>
                </Link>
                <a className='gameCard-container mt-3'
                 href={`whatsapp://send?phone=${ JSON.parse(localStorage.getItem("sitSetting")) ==
                  "undefined"
                    ? ""
                    : JSON.parse(localStorage.getItem("sitSetting"))
                        ?.saffronSupport}&text=%F0%9F%99%8FWELCOME%20TO%20SAFFRON%20%20EXCH%20%F0%9F%99%8F%0A%0A%0A%E2%80%94%E2%80%94%E2%80%94%F0%9F%8F%8F%F0%9F%8F%86%F0%9F%8E%AF%E2%9A%BD%F0%9F%8F%87%F0%9F%8E%B1%F0%9F%8F%91%E2%99%9F%F0%9F%8E%BE%E2%80%94%E2%80%94%E2%80%94%0A%F0%9D%97%A0%F0%9D%97%B6%F0%9D%97%BB%F0%9D%97%B6%F0%9D%97%BA%F0%9D%98%82%F0%9D%97%BA%20500%20%5B%20%F0%9D%97%9C%F0%9D%97%97%2C%2C%2C%F0%9D%97%97%F0%9D%97%B2%F0%9D%97%BD%F0%9D%97%BC%F0%9D%98%80%F0%9D%97%B6%F0%9D%98%81%2C%2C%F0%9D%97%AA%F0%9D%97%B6%F0%9D%98%81%F0%9D%97%B5%F0%9D%97%B1%F0%9D%97%BF%F0%9D%97%AE%F0%9D%98%84%F0%9D%97%AE%F0%9D%97%B9%20%5D%0A%0A%F0%9D%9F%AE%F0%9D%9F%B0%2F%F0%9D%9F%B3%20%F0%9D%97%94%F0%9D%97%BB%F0%9D%98%86%F0%9D%98%81%F0%9D%97%B6%F0%9D%97%BA%F0%9D%97%B2%20%F0%9D%97%97%F0%9D%97%B2%F0%9D%97%BD%F0%9D%97%BC%F0%9D%98%80%F0%9D%97%B6%F0%9D%98%81%20%26%20%F0%9D%97%AA%F0%9D%97%B6%F0%9D%98%81%F0%9D%97%B5%F0%9D%97%B1%F0%9D%97%BF%F0%9D%97%AE%F0%9D%98%84%F0%9D%97%AE%F0%9D%97%B9%20%F0%9D%97%AA%F0%9D%97%B6%F0%9D%98%81%F0%9D%97%B5%F0%9D%97%BC%F0%9D%98%82%F0%9D%98%81%20%F0%9D%97%94%F0%9D%97%BB%F0%9D%98%86%20%F0%9D%97%9F%F0%9D%97%B6%F0%9D%97%BA%F0%9D%97%B6%F0%9D%98%81%F0%9D%98%80%20%F0%9D%97%94%F0%9D%98%83%F0%9D%97%AE%F0%9D%97%B6%F0%9D%97%B9%F0%9D%97%AE%F0%9D%97%AF%F0%9D%97%B9%F0%9D%97%B2.%0A%0A%0A%0A%20%F0%9F%91%89%20https%3A%2F%2Fsaffronexch.com%C2%A0%F0%9F%91%88%0A
`}
>


                   {/* to={`/colorPredication`}> */}
                  <span className='d-none blink text-danger d-block text-right game_status_live'>
                    ◉ LIVE
                  </span>
                  <picture className='gameCard-image'>
                    <img
                     
                      src={
                        process.env.PUBLIC_URL +
                        './mainimage/saffron.jpg'
                      }
                      alt=''
                    />
                  </picture>
                </a>
                <a
                className='gameCard-container mt-3'
                        href={
                          JSON.parse(localStorage.getItem("sitSetting")) ==
                          "undefined"
                            ? ""
                            : JSON.parse(localStorage.getItem("sitSetting"))
                                ?.whatsappSupport
                        }
                        target="_blank"
                      >
                  <span className='d-none blink text-danger d-block text-right game_status_live'>
                    ◉ LIVE
                  </span>
                  <picture className='gameCard-image'>
                    <img
                     
                      src={
                        process.env.PUBLIC_URL +
                        './mainimage/support.jpg'
                      }
                      alt=''
                    />
                  </picture>
                </a>
                {/* =================Ludo Ulta================ */}
                {/* <Link className='gameCard-container' to={`/Homepage/Ludo%20Ulta`}>
                  // <span className='d-none blink text-danger d-block text-right game_status_live'>
                  //   ◉ LIVE
                  // </span>
                  <picture className='gameCard-image'>
                    <img
                      width='100%'
                      src={
                        process.env.PUBLIC_URL +
                        './mainimage/ulta-ludo.jpg'
                      }
                      alt=''
                    />
                  </picture>
                </Link> */}
                {/* <Link className="gameCard-container" to={`/Homepage/Ludo%20Ulta`} > */}

                {/* =================Ludo Snake================ */}
                {/* <Link className='gameCard-container' to={`/Homepage/Ludo%20Snake`}>
                  // <span className='d-none blink text-danger d-block text-right game_status_live'>
                  //   ◉ LIVE
                  // </span>
                  <picture className='gameCard-image'>
                    <img
                      width='100%'
                      src={
                        process.env.PUBLIC_URL +
                        './mainimage/ulta-snake.jpg'
                      }
                      alt=''
                    />
                  </picture>
                </Link> */}

                {/* =================Ludo skilltox================ */}
                {/* <Link className='gameCard-container' to={``}>
                  <span className='d-none blink text-danger d-block text-right game_status_live'>
                    ◉ Comming Soon
                  </span>
                  <picture className='gameCard-image'>
                    <img
                      width='100%'
                      src={
                        process.env.PUBLIC_URL +
                        './mainimage/skilltoxludo.jpg'
                      }
                      alt=''
                    />
                  </picture>
                </Link> */}
                  
                </>

                
                </div>
              </div>
            </section>

            {/* <div className='downloadButton'>
              <Downloadbutton />
            </div> */}
          </div>
        </div>
      </div>
      <div className='rightContainer'>
        <Rightcontainer />
      </div>
    </>
  )
}
