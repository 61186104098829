import React, { useEffect, useState } from "react";
import css from "../css/Refer.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

import { BsWhatsapp } from "react-icons/bs";

import { AiOutlineInstagram, AiFillFacebook } from "react-icons/ai";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaTelegramPlane } from "react-icons/fa";
import { FiCopy } from "react-icons/fi";

const Refer = ({ user }) => {
  const addsetting = localStorage.getItem("sitSetting");
  const addCaseSetting = JSON.parse(addsetting);

  const [cardData, setGame] = useState([]);

  const copyCode = (e) => {
    navigator.clipboard.writeText(user.referral_code);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Referral Code Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };

  const copyLink = (e) => {
    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Referal Link Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };

  if (user === undefined) {
    return null;
  }

  const referralText = `skilltox Ludo पर लूडो खेल कर या दोस्तों को शेयर कर के पैसा जीतो और तुरंत अपने Bank या UPI में ट्रांसफर कर सकते है।
  दोस्तो को शेयर करने पर उनका आपको लाइफ टाइम 2% कमीशन दिया जाएगा  
  
    100% भरोसेमंद प्लेटफार्म। 24 hours support https://skilltox.com/login/${user.referral_code}
  
  Your ReferralCode is - ${user.referral_code}.`;

  return (
    <>
      {/* <Header user={user} /> */}
      <div>
        <div
          className="leftContainer mb_space"
          style={{ minHeight: "100vh", height: "100%" }}
        >
          <section className="refer_page_main_section">
            {/* <div className="refer-media text-center">
            <picture class="mt-1"><img alt="img" width="226px" src="images/refer.png" class="snip-img"/></picture>
            </div>
            <div className="font-15 text-center" style={{ fontSize: '1.8rem' }}>
              Earn now unlimited<span aria-label="party-face" role="img">🥳</span>
            </div>
            <div className="d-flex justify-content-center font-weight-bold">
              Refer your friends now!
            </div>
            <div className="text-center font-9">
              Total Earned: <b>0</b>
            </div>
            <div className="Refer_progress__1B5rH">
            <div
              className="Refer_progress_bar__QDeat Refer_progress_bar_striped__nFrNS Refer_bg_success__3Ooro"
              aria-valuenow="0"
              aria-valuemax="10000"
              style={{ width: '0%' }}
            ></div>
            
            
          </div>
          <div className="font-9">
            <span>Max: 10,000</span>
            <a className="float-right" href="/update-pan">
              Upgrade Limit
            </a>
         </div>
         <div className="Refer_code">
                  <h3>Your Refer Code: {user.referral_code}</h3>

                  <button onClick={(e) => copyCode(e)}>Copy</button>
                </div>

              <h3 className="rules-title">
              Refer & Earn Rules
              </h3>

              <div className="d-flex align-items-center m-3">
      <picture>
        <img alt="img" width="82px" src="/images/giftbanner.png" className="snip-img" />
      </picture>
      <div className="Refer_font_9__xYPqc mx-3" style={{ width: '62%' }}>
        <div class="rules-title-1">
          When your friend signs up on RK Winzo from your referral link,
        </div>
        <div className="Refer_font_8__cCSAy Refer_c_green__iSUer mt-2">
          You get<strong>&nbsp; 2% Commission</strong>&nbsp;on your<strong>&nbsp; referral's winnings.</strong>
        </div>
      </div>
    </div>
    <div className="d-flex align-items-center m-3">
      <picture>
        <img alt="img" width="82px" src="/images/banner.png" className="snip-img" />
      </picture>
      <div className="Refer_font_9__xYPqc mx-3" style={{ width: '62%' }}>
        <div class="rules-title-1">
        Suppose your referral plays a battle for a 10000 Cash,
        </div>
        <div className="Refer_font_8__cCSAy Refer_c_green__iSUer mt-2">
        You get<strong> 200 Cash</strong>
        </div>
      </div>
    </div> */}
          
            <div className="refer_page_top_main_box_outer mb-5">
              <div className="refer_page_top_main_box">
                <h2 className="profile_headings">Your Referral Earnings</h2>
              </div>
              <div className="refer_page_top_main_box_inner">
               <div className="refer_page_top_main_box_inner_single bo_le">
                  <h3>Referred Players</h3>
                  <h4>
                    
                    {user.referral_user?.length}
                  </h4>
                </div>
               <div className="refer_page_top_main_box_inner_single">
                  <h3>Referral Earning</h3>
                  <h4>
                    <span>₹</span>
                    {user.referral_earning}
                  </h4>
                </div>
              </div>
              
            </div> 

         

             <div className="refer_page_top_main_box_outer">
              <div className="refer_page_top_main_box">
                <h2 className="profile_headings">Referral Code</h2>
              </div>
              <div>
                <div className="Refer_code">
                  <h3>{user.referral_code}</h3>

                  <button onClick={(e) => copyCode(e)}>Copy</button>
                </div>
                <div className="or_div">
                  {" "}
                  <h2>OR</h2>{" "}
                </div>
                <div className="whatsapp_share_btn">
                <a
                 href={`whatsapp://send?text=Hi%20Friends%2C%20Play%20skilltox%20%20and%20earn%2010000%20daily.%20Download%20Indian%20App%20skilltox%0A%0A%20%20skilltox%20%E0%A4%AA%E0%A4%B0%20%E0%A4%B2%E0%A5%82%E0%A4%A1%E0%A5%8B%20%E0%A4%96%E0%A5%87%E0%A4%B2%20%E0%A4%95%E0%A4%B0%20%E0%A4%AF%E0%A4%BE%20%E0%A4%A6%E0%A5%8B%E0%A4%B8%E0%A5%8D%E0%A4%A4%E0%A5%8B%E0%A4%82%20%E0%A4%95%E0%A5%8B%20%E0%A4%B6%E0%A5%87%E0%A4%AF%E0%A4%B0%20%E0%A4%95%E0%A4%B0%20%E0%A4%95%E0%A5%87%20%E0%A4%AA%E0%A5%88%E0%A4%B8%E0%A4%BE%20%E0%A4%9C%E0%A5%80%E0%A4%A4%E0%A5%8B%20%E0%A4%94%E0%A4%B0%20%E0%A4%A4%E0%A5%81%E0%A4%B0%E0%A4%82%E0%A4%A4%20%E0%A4%85%E0%A4%AA%E0%A4%A8%E0%A5%87%20Bank%20%E0%A4%AF%E0%A4%BE%20UPI%20%E0%A4%AE%E0%A5%87%E0%A4%82%20%E0%A4%9F%E0%A5%8D%E0%A4%B0%E0%A4%BE%E0%A4%82%E0%A4%B8%E0%A4%AB%E0%A4%B0%20%E0%A4%95%E0%A4%B0%20%E0%A4%B8%E0%A4%95%E0%A4%A4%E0%A5%87%20%E0%A4%B9%E0%A5%88%E0%A5%A4%0A%E0%A4%A6%E0%A5%8B%E0%A4%B8%E0%A5%8D%E0%A4%A4%E0%A5%8B%20%E0%A4%95%E0%A5%8B%20%E0%A4%B6%E0%A5%87%E0%A4%AF%E0%A4%B0%20%E0%A4%95%E0%A4%B0%E0%A4%A8%E0%A5%87%20%E0%A4%AA%E0%A4%B0%20%E0%A4%89%E0%A4%A8%E0%A4%95%E0%A4%BE%20%E0%A4%86%E0%A4%AA%E0%A4%95%E0%A5%8B%20%E0%A4%B2%E0%A4%BE%E0%A4%87%E0%A4%AB%20%E0%A4%9F%E0%A4%BE%E0%A4%87%E0%A4%AE%202%25%20%E0%A4%95%E0%A4%AE%E0%A5%80%E0%A4%B6%E0%A4%A8%20%E0%A4%A6%E0%A4%BF%E0%A4%AF%E0%A4%BE%20%E0%A4%9C%E0%A4%BE%E0%A4%8F%E0%A4%97%E0%A4%BE%20%20%0A%0A%20%20100%25%20%E0%A4%AD%E0%A4%B0%E0%A5%8B%E0%A4%B8%E0%A5%87%E0%A4%AE%E0%A4%82%E0%A4%A6%20%E0%A4%AA%E0%A5%8D%E0%A4%B2%E0%A5%87%E0%A4%9F%E0%A4%AB%E0%A4%BE%E0%A4%B0%E0%A5%8D%E0%A4%AE%E0%A5%A4%2024%20hours%20support%20https%3A%2F%2Fskilltox.com%2Flogin%2F${user.referral_code}%0A%20%20%0A%20%20Your%20ReferralCode%C2%A0is%C2%A0${user.referral_code}.`}
                >
                    <BsWhatsapp className="icons" />
                  </a>

                  <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=#url?text=Play  skilltox Ludo पर लूडो खेल कर या दोस्तों को शेयर कर के पैसा जीतो और तुरंत अपने Bank या UPI में ट्रांसफर कर सकते है।
                    दोस्तो को शेयर करने पर उनका आपको लाइफ टाइम 2% कमीशन दिया जाएगा  
                    
                      100% भरोसेमंद प्लेटफार्म। 24 hours support https://skilltox.com/login/${user.referral_code}
                    
                    Your ReferralCode is - ${user.referral_code} ..https://skilltox.com/login/${user.referral_code}Register Now, My refer code is ${user.referral_code}.`}
                  target='_blank'
                >
                    {" "}
                    <AiFillFacebook className="icons" />
                  </a>

                  <a
                  href={`https://telegram.me/share/url?url?text=Play  Skill Ludo पर लूडो खेल कर या दोस्तों को शेयर कर के पैसा जीतो और तुरंत अपने Bank या UPI में ट्रांसफर कर सकते है।
दोस्तो को शेयर करने पर उनका आपको लाइफ टाइम 2% कमीशन दिया जाएगा  

  100% भरोसेमंद प्लेटफार्म। 24 hours support.https://skilltox.com/login/${user.referral_code}Register Now, My refer code is ${user.referral_code}.`}
                  target='_blank'
                >
                    <FaTelegramPlane className="icons" />
                  </a>

                  <CopyToClipboard text={referralText}>
                    <button
                      className="bg-green refer-button cxy bg3 "
                      onClick={(e) => copyLink(e)}
                    >
                      <FiCopy className="icons" />
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            </div> 

            <div className="refer_page_top_main_box_outer mt-5">
              <div className="refer_page_top_main_box">
                <h2 className="profile_headings">How It Works</h2>
              </div>
              <div className="refer_page_top_main_box_inner">
                <div className="refer_rules">
                  <p>
                    1. You can refer and Earn 3% of your referral winning, every
                    time
                  </p>
                  <p>
                    2. Like if your player plays for More Amount. 10000 and
                    wins, You will get Ruppe. 300 as referral amount.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Refer;
